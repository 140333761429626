
/* Landscape */
@media only screen and (min-width: 1081px) { 
  .share-container, .popup-success {
    width: 60%;
    left: 20%;
  }
  .agency-name {
    margin: 50px 0;
  }
  .demo-item {
    width: 220px;
    height: 220px;
  }
  .demo-item img {
    width: 170px;
  }
}
