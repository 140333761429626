.key-button {
    display: inline-block;
    margin: 5px;
    padding: 5px;
    background: #009698;
    color: white;
    font-size: 30px;
    width: calc(10% - 10px);
    height: 81px;
    vertical-align: middle;
    text-align: center;
    line-height: 65px;
    border-radius: 5px;
    z-index: 9999;
    position: relative;
}
.share-container input[type='submit'] {    
    margin: 5px 5px 0px 5px;
    width: calc(90% - 10px);
    display: inline-block;
    height: 80px;
}
.share-container input {
    margin: 10px 5px;
    height: 85px;
    border: 1px solid #696969;
    border-radius: 5px;
    width: 99%;
    display: block;
    padding: 0 20px;
    font-size: 32px;
}

.share-btn {
    background-color: #2dbe86;
    width: 99%;
    border: none;
    margin-top: 15px;
    height: 60px;
    font-size: 36px;
    color: #fff;
    border-radius: 5px;
}