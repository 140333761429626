.App {
  text-align: center;
  background-size: cover;
  height: 100vh;
  margin: 0 auto;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px +  3vmin);
  padding: 10px;
  color: #282c34;
}

.App-footer{
  height:80px;
  width: 100%;
  background-color: #666;
  width: 100%;
  margin: 0 auto;
  padding: 10px 0;
}

.App-link {
  color: #3daec4;
}

.App > .container-fluid {
  position: relative;
  z-index: 1;
}
.App > .container-fluid.send-background{
  z-index: -1;
}

.agency-name {
  margin: 100px 0;
  color: #05838f;
}
.agency-name h2{
  /* color: #05838f; */
  font-size: calc(10px + 5vmin);
}
.agency-name h1.aname{
  /* color: #05838f; */
  font-size: calc(10px + 10vmin);
}
.demo-row{
  text-align: left;
  display: inline-block;
  width: 100%;
}
.demo-item-container{
  padding: 5px;
  text-align: center;
  vertical-align: top;
  display: flex;
}

.demo-item{
  background-color: #fff;
  box-shadow: 0px 5px 7px -2px rgba(0,0,0,.5);
  width: 250px;
  border-radius: 40px;
  height: 250px;
}

.demo-item img{
  width: 200px;
  max-height: 100%;
}

.demo-item-name {
  color: #fff;
  font-size: 32px;
  width: 100%;
  display: block;
  max-width: 250px;;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footer-icon {
  border-radius: 100%;
  background-color: #fff;
  padding: 10px;
  width: 60px;
  height: 60px;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
}
.footer-icon img{
  max-width: 70px;
  height: 100%;
  text-align: center;
}
footer .d-inline span{
  line-height: 60px;
  height: 60px;
  display: inline-flex;
  vertical-align: top;
  margin-right: 20px;
  color: #fff;
  font-size: 30px;
  text-transform: uppercase;
}

.container-fluid iframe{
  height: calc(100vh - 80px);
  border: none;
}

.share-container, .popup-success {
  position: fixed;
  width: 90%;
  margin: 0 auto;
  z-index: 9999;
  bottom: 100px;
  background-color: #fff;
  left: 5%;
  text-align: center;
  padding: 30px;
  border-radius: 40px;
  box-shadow: 0px 0px 10px rgba(0,0,0,.5);
}
.popup-success {
  height: 250px;
}
.popup-message {
  font-size: 36px;
  margin: 1em 0;
  color: #2dbe86;
}
.slick-track {
  width: 100% !important;
}
.magic-dots.slick-dots li button:before {
  font-size: 80px;
}
.slick-dots li button:before {
  line-height: 80px;
  width: 80px;
  height: 80px;
}
.slick-dots li button {
  width: 80px;
  height: 80px;
}
.slick-dots li {
  width: 80px;
  height: 80px;
}
.slick-dots li {
  display: inline-block;
}
.slick-dots{
  width: auto !important;
}
.magic-dots.slick-dots ul{
  display: inline !important;
}
.share-container .text-danger{
  height: 24px;
  line-height: 16px;
}
.back-key {
  background: #ee4827;
  display: inline-block;
  vertical-align: top;
}

.share-container input.error {
  border-color: #ee4827;
  background: url(../../assets/images/icons/input-error.svg) no-repeat;
  background-size: 80px;
  background-position: 99.7%;
}

.rotation-popup {
  position: absolute;
  left: 0;
  width: 100%;
  height: calc( 100% - 80px);
  background: rgba(255,255,255,.8);
  display: flex;
  align-items: center;
  z-index: 998;
}
.rotation-message {
  display: block;
  margin: 0 auto;
  font-size: 40px;
}
.popup-opened iframe, .popup-opened iframe img, .popup-opened iframe div {
  position: relative;
  z-index: -1;
}

#exit-popup {
  margin-top: -20px;
  z-index: 9999;
}
#exit-popup p {
  color: #000;
  font-weight: bold;
}
#exit-popup button {
  padding: 10px 20px;
  margin: 20px 20px 0 20px;
  width: 25%;
  background: #009688;
  font-size: 28px;
}

.react-multi-carousel-list{
  display: grid;
}

#screensaver {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  /* background-color: #000; */
}
#screensaver > video{
  width: 100%;
  height: 100%;
}

span[type='prev'], span[type='next'] {
  opacity: 0;
  width: 0;
  height: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
